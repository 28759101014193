import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Redirect from "components/Redirect"
import FormEditCompany from "./components/FormEditCompany"
import { useParameters } from "hooks/useParameters"

const EditCompany = () => {
  const {
    group_id: [groupId],
    current_page: [currentPage],
  } = useParameters("group_id", "current_page")

  if (!groupId) return <Redirect to={ROUTES.home.path} />

  return (
    <Can I="management" the="companies">
      <PageHeader title="Editar empresa" />
      <section className="edit-company">
        <FormEditCompany groupId={groupId} currentPage={currentPage} />
      </section>
    </Can>
  )
}

export default EditCompany
