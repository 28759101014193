import Form from "components/Forms/Form"
import Button from "components/Button"
import Card from "components/Card"
import Switch from "components/Forms/Switch"
import Input from "components/Forms/Input"
import maskers from "utils/maskers"
import useFormEditCompany from "./hooks"

import { validation } from "./form"

import "./FormEditCompany.styl"

const FormEditCompany = ({ groupId, currentPage }) => {
  const { values, businesses, onSubmit } = useFormEditCompany({
    groupId,
    currentPage,
  })

  return (
    <Form
      className="form-edit-company"
      onSubmit={onSubmit}
      defaultValues={values}
      schema={validation}
      manually={true}
      reinitialize={true}
    >
      {({ form, state: { isDisabled } }) => (
        <>
          <Card title="Informações da empresa" radius="medium">
            <Input
              className="form-edit-user__input"
              label="Nome da empresa"
              name="name"
              data-test="company"
              placeholder="Nome"
              form={form}
            />
            <Input
              className="form-edit-user__input"
              label="CNPJ"
              name="document_number"
              placeholder="Opcional"
              data-test="document"
              mask={maskers.cnpj}
              form={form}
            />
          </Card>
          <Card title="Produtos" radius="medium">
            <ul className="form-edit-company__list">
              {businesses.map((business) => (
                <li
                  className="form-edit-company__item"
                  key={business.business_id}
                >
                  <Switch
                    data-test="company"
                    legend={business.business_name}
                    name="business_ids"
                    value={String(business.business_id)}
                    form={form}
                  />
                </li>
              ))}
            </ul>
          </Card>
          <Button
            className="form-edit-company__submit"
            type="submit"
            data-test="submit"
            size="big"
            disabled={isDisabled}
          >
            Salvar
          </Button>
        </>
      )}
    </Form>
  )
}

export default FormEditCompany
