import * as Yup from "yup"
import validators from "utils/validators"

export const values = {
  name: "",
  document_number: "",
}

export const validation = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  document_number: Yup.string().test(
    "validDocument",
    "Documento inválido",
    (value) => validators.cnpj(value)
  ),
})

export default {
  values,
  validation,
}
