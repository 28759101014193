import { useCallback, useEffect, useState } from "react"
import { navigate } from "gatsby"
import auth from "utils/auth"
import helpers from "utils/helpers"
import { getEditGroup } from "services/bff/edit"
import { setCheckedFields } from "./utils"
import message from "utils/message"
import services from "services"

import { values as formValues } from "./form"

const useFormEditCompany = ({ groupId, currentPage }) => {
  const user = auth.getUser()
  const [values, setValues] = useState(formValues)
  const [businesses, setBusinesses] = useState([])

  const fetchPage = useCallback(() => {
    getEditGroup({ group_id: groupId, user_id: user?.user_id }).then(
      handleFields
    )
  }, [groupId, user.user_id])

  const handleFields = ({ group, businesses }) => {
    setBusinesses(businesses)
    setValues({
      name: group?.name,
      document_number: group?.document_number,
      business_ids: setCheckedFields(businesses),
    })
  }

  const onSubmit = (formData) => {
    services.group.edit(formData, groupId).then(handleEdit)
  }

  const handleEdit = async ({ error }) => {
    if (!error) {
      message({ notification: "Empresa editada" })
      await helpers.wait(2000)
      return navigate(`${ROUTES.companies.path}?current_page=${currentPage}`)
    }
  }

  useEffect(() => {
    fetchPage()
  }, [fetchPage])

  return {
    values,
    businesses,
    onSubmit,
  }
}

export default useFormEditCompany
